
import { Component, Vue } from "vue-property-decorator";
import WeatherForecast from "@/components/WeatherForecast.vue";

@Component({
  components: {
    WeatherForecast,
  },
})
export default class Home extends Vue {}
