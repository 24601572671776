
import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import SnackBar from "@/components/SnackBar.vue";

@Component({
  components: {
    Header,
    SnackBar,
  },
})
export default class App extends Vue {}
