import {
  PermissionLevel,
  PermissionService,
  ScopedPermission,
} from "../services/PermissionService";
import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

export interface State {
  userPermissions: ScopedPermission[];
  userId: number;
}

const vuexLocalStorage = new VuexPersistence({
  key: process.env.VUE_APP_LOCAL_STORAGE_KEY,
  storage: window.localStorage,
  reducer: (state: State) => ({
    userPermissions: state.userPermissions,
    userId: state.userId,
    // If needed, put mutation filters here
  }),
});

function loadAllPermissions(userPermissions: string[]): ScopedPermission[] {
  const permissions: ScopedPermission[] = [];
  userPermissions.forEach((up) => {
    const assignedPermissionLevel = PermissionService.getLevel(up);
    const permission = PermissionService.getPermission(up);

    if (assignedPermissionLevel && permission) {
      Object.values(PermissionLevel).forEach((pl) => {
        const permissionLevel =
          PermissionLevel[pl as keyof typeof PermissionLevel];
        if (assignedPermissionLevel >= permissionLevel) {
          permissions.push({ permissionLevel, permission });
        }
      });
    }
  });
  return permissions;
}

export default new Vuex.Store<State>({
  plugins: [vuexLocalStorage.plugin],
  state: {
    userPermissions: [],
    userId: 0,
  },
  mutations: {
    updateSessionInfo(state, payload) {
      if (payload.userPermissions) {
        state.userPermissions = loadAllPermissions(payload.userPermissions);
      }
      if (payload.userId) {
        state.userId = payload.userId;
      } 
    },
    deleteSessionInfo(state) {
      state.userPermissions = [];
    },
  },
  actions: {
    setSessionInfo(context, payload) {
      context.commit("updateSessionInfo", payload);
    },
    deleteSessionInfo(context) {
      context.commit("deleteSessionInfo");
    },
  },
  getters: {
    getUserPermissions(state) {
      return state.userPermissions;
    },
    getUserId(state) {
      return state.userId;
    },
  },
});
