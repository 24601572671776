import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import { authGuard } from "../auth/authGuard";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/catches",
    name: "catches",
    component: () =>
      import(/* webpackChunkName: "mi-ty-catches" */ "../views/CatchList.vue"),
    beforeEnter: (to, from, next) => authGuard(to, from, next, "read:price"),
  },
  {
    path: "/Settings",
    name: "Settings",
    component: () =>
      import(/* webpackChunkName: "mi-ty-settings" */ "../views/Settings.vue"),
    beforeEnter: (to, from, next) => authGuard(to, from, next, "del:admin"),
  },
  {
    path: "/SettingsPrice",
    name: "SettingsPrice",
    component: () =>
      import(/* webpackChunkName: "mi-ty-price" */ "../views/Price.vue"),
    beforeEnter: (to, from, next) => authGuard(to, from, next, "edit:price"),
  },
  {
    path: "/BulkPrice",
    name: "BulkPrice",
    component: () =>
      import(/* webpackChunkName: "mi-ty-price" */ "../views/BulkPrice.vue"),
    beforeEnter: (to, from, next) => authGuard(to, from, next, "edit:catch"),
  },
  {
    path: "/user",
    name: "User",
    component: () =>
      import(/* webpackChunkName: "mi-ty-settings" */ "../views/User.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
