import { getInstance } from "./index";
import permissionService from "@/services/PermissionService";
import { bus } from "@/main";

export const authGuard = (to, from, next, permission) => {
  const authService = getInstance();

  const fn = () => {
    // If the user is authenticated, continue with the route
    if (authService.isAuthenticated) {
      if (!permission || permissionService.hasPermissionString(permission)) {
        return next();
      } else {
        bus.$emit("messageBar", "Permission Denied");
        return from();
      }
    }

    // Otherwise, log in
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If loading has already finished, check the auth state using `fn()`
  if (!authService.loading) {
    return fn();
  }

  // Watch for the loading property to change before checking isAuthenticated
  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};
