import axios from "axios";
import { getInstance } from "../auth/index.js";

export default class BaseService {
  endpoint: string;
  requiresAuth: boolean;
  baseUrl = process.env.VUE_APP_API_URL || "/devapi/";

  constructor(endpoint: string, requiresAuth = true) {
    this.endpoint = endpoint;
    this.requiresAuth = requiresAuth;
  }

  protected getAxios() {
    return axios;
  }

  protected getUrl() {
    return `${this.baseUrl}${this.endpoint}`;
  }

  async getAll() {
    return axios
      .get(this.getUrl(), await this.getAuthorization())
      .then((resp) => {
        return resp.data.data;
      });
  }

  async getCount() {
    return axios.get(this.getUrl(), this.countParam()).then((resp) => {
      return resp.data.meta.count;
    });
  }

  async getList(page?: number) {
    return axios.get(this.getUrl(), this.pageParam(page)).then((resp) => {
      return resp.data.data;
    });
  }

  async get(id: number) {
    return axios
      .get(this.getUrl() + `/${id}`, await this.getAuthorization())
      .then((resp) => {
        return resp.data.data;
      });
  }

  async create(body: any) {
    return axios
      .post(this.getUrl(), body, await this.getAuthorization())
      .then((resp) => {
        return resp.data.data;
      });
  }

  async update(id: number, body: any) {
    return axios
      .put(this.getUrl() + `/${id}`, body, await this.getAuthorization())
      .then((resp) => {
        return resp.data.data;
      });
  }

  async delete(id: number) {
    return axios
      .delete(this.getUrl() + `/${id}`, await this.getAuthorization())
      .then((resp) => {
        return resp.data.data;
      });
  }

  private buildParams(params?: Map<string, any>) {
    if (!params) {
      return {};
    }
    const result = new URLSearchParams();

    for (const [key, value] of params) {
      if (value) {
        result.append(key, value);
      }
    }
    return { params: result };
  }

  private pageParam(page?: number) {
    return this.buildParams(new Map([["page", page]]));
  }

  private countParam() {
    return this.buildParams(new Map([["count", true]]));
  }

  protected async getAuthorization() {
    if (!this.requiresAuth) {
      return undefined;
    }
    const accessToken = await getInstance().getTokenSilently();
    return !accessToken
      ? {}
      : {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
  }
}
