import Vue from "vue";
import vuetify from "./plugins/vuetify"; // path to vuetify export
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { RawLocation } from "vue-router";
import { Auth0Plugin } from "./auth";
import VueLodash from "vue-lodash";
import lodash from "lodash";
import ToggleButton from "vue-js-toggle-button";
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
import VModal from "vue-js-modal";
import VueConfirmDialog from "vue-confirm-dialog";
import VueCookie from "vue-cookie";
import VueMoment from "vue-moment";
import VueExcelXlsx from "vue-excel-xlsx";

export const bus = new Vue();

Vue.config.productionTip = false;

const currencyFormatter = new Intl.NumberFormat("en-CA", {
  style: "currency",
  currency: "CAD",
});
const weightFormatter = new Intl.NumberFormat("en-CA", {
  style: "unit",
  unit: "pound",
});

Vue.mixin({
  methods: {
    currencyFormat: (number) => {
      return currencyFormatter.format(number ?? 0);
    },
    weightFormat: (number) => {
      return weightFormatter.format(number ?? 0);
    },
  },
});

Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH_DOMAIN,
  clientId: process.env.VUE_APP_AUTH_CLIENTID,
  audience: process.env.VUE_APP_AUTH_AUDIENCE,
  onRedirectCallback: (appState: { targetUrl: RawLocation }) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});

Vue.use(VueLodash, { name: "custom", lodash: lodash });

Vue.use(VueExcelXlsx);
Vue.use(ToggleButton);
Vue.use(VueMaterial);
Vue.use(VModal);
Vue.use(VueConfirmDialog);
Vue.use(VueCookie);
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);
Vue.use(VueMoment);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
