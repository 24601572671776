
import { Component, Vue } from "vue-property-decorator";
import WeatherService, {
  degreesToIntercardinalDirection,
  mpsToKnots,
} from "@/services/WeatherService";
import { bus } from "@/main";
import moment from "moment";
const STATIC_URL = process.env.VUE_APP_STATIC_URL || "/devstatic/";

@Component
export default class WeatherForecast extends Vue {
  city: string | null = "Kensington";
  wind: string | null = null;
  temp: number | string | null = null;
  uvi: number | string | null = null;
  iconPath: string | null = null;
  weather: string | null = null;
  daily: any[] = [];

  created() {
    this.getForecast();
  }

  getDay(n) {
    return n == 0
      ? "Today"
      : n == 1
      ? "Tomorrow"
      : moment().add(n, "days").format("dddd");
  }

  getDayAbbrev(n) {
    return moment().add(n, "days").format("dddd").substring(0, 3);
  }

  getDayTemp(day) {
    return `${Math.round(day.temp.min)}°C - ${Math.round(day.temp.max)}°C`;
  }

  getForecast() {
    this.getForecastForLocation(46.587129956650244, -63.61768643199708);
  }

  getLocalForecast() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.getForecastForLocation(
            position.coords.latitude,
            position.coords.longitude
          );
        },
        (error) => {
          bus.$emit(
            "messageBar",
            "Error occured getting location. Please make sure website has access to browser location"
          );
        },
        {
          timeout: 30000,
          enableHighAccuracy: true,
        }
      );
    }
  }

  getForecastForLocation(lat: number, lng: number) {
    WeatherService.getForecastByCoords(lat, lng)
      .then((resp) => {
        const windDir = resp.current?.wind_deg
          ? degreesToIntercardinalDirection(resp.current.wind_deg)
          : "";
        const windSpeed = resp.current?.wind_speed
          ? ` ${Math.round(mpsToKnots(resp.current.wind_speed))}`
          : "";
        const windGust = resp.current?.wind_gust
          ? ` gust ${Math.round(mpsToKnots(resp.current.wind_gust))}`
          : "";
        const units = windSpeed || windGust ? " kn" : "";
        this.wind = `${windDir}${windSpeed}${windGust}${units}`;
        this.temp = resp.current?.temp ? Math.round(resp.current.temp) : "";
        this.uvi = resp.current?.uvi ? resp.current.uvi : "";

        this.iconPath = resp.current?.weather[0]?.icon
          ? this.getIconPath(resp.current.weather[0].icon)
          : "";
        this.weather = resp.current?.weather[0]?.description
          ? resp.current.weather[0].description[0].toUpperCase() +
            resp.current.weather[0].description.substring(1)
          : "";

        this.daily = resp.daily;
      })
      .catch(() => {
        bus.$emit("messageBar", "Error: Couldn't fetch weather");
      });
  }

  getIconPath(icon) {
    return STATIC_URL + "openweathericons/" + icon + ".png";
  }

  getDailyWind(day) {
    const windDir = day?.wind_deg
      ? degreesToIntercardinalDirection(day.wind_deg)
      : "";
    const windSpeed = day?.wind_speed
      ? ` ${Math.round(mpsToKnots(day.wind_speed))}`
      : "";
    const units = windSpeed ? " kn" : "";
    return `${windDir}${windSpeed}${units}`;
  }

  mpsToKnots(mps) {
    return mpsToKnots(mps);
  }
}
