import axios from "axios";
import BaseService from "./BaseService";

export class WeatherService extends BaseService {
  constructor() {
    super("weather");
  }

  async getWeatherByCoords(lat: number, lon: number) {
    const params = {
      lat: lat,
      lon: lon,
    };

    return axios
      .get(this.getUrl() + "/weather", { params: params })
      .then((resp) => {
        return resp.data;
      });
  }

  async getForecastByCoords(lat: number, lon: number) {
    const params = {
      lat: lat,
      lon: lon,
    };

    return axios
      .get(this.getUrl() + "/forecast", { params: params })
      .then((resp) => {
        return resp.data;
      });
  }

  static degreesToIntercardinalDirection(degree: number): string {
    let direction = "";
    if (degree >= 348.75 || degree < 11.25) {
      direction = "N";
    } else if (degree >= 11.25 && degree < 33.75) {
      direction = "NNE";
    } else if (degree >= 33.75 && degree < 56.25) {
      direction = "NE";
    } else if (degree >= 56.25 && degree < 78.75) {
      direction = "ENE";
    } else if (degree >= 78.75 && degree < 101.25) {
      direction = "E";
    } else if (degree >= 101.25 && degree < 123.75) {
      direction = "ESE";
    } else if (degree >= 123.75 && degree < 146.25) {
      direction = "SE";
    } else if (degree >= 146.25 && degree < 168.75) {
      direction = "SSE";
    } else if (degree >= 168.75 && degree < 191.25) {
      direction = "S";
    } else if (degree >= 191.25 && degree < 213.75) {
      direction = "SSW";
    } else if (degree >= 213.75 && degree < 236.25) {
      direction = "SW";
    } else if (degree >= 236.25 && degree < 258.75) {
      direction = "WSW";
    } else if (degree >= 258.75 && degree < 281.25) {
      direction = "W";
    } else if (degree >= 281.25 && degree < 303.75) {
      direction = "WNW";
    } else if (degree >= 303.75 && degree < 326.25) {
      direction = "NW";
    } else if (degree >= 326.25 && degree < 348.75) {
      direction = "NNW";
    }
    return direction;
  }

  static mpsToKph(mps: number) {
    return (mps * 18) / 5;
  }

  static mpsToKnots(mps: number) {
    return mps * (1852 / 3600);
  }
}

export default new WeatherService();
export const degreesToIntercardinalDirection =
  WeatherService.degreesToIntercardinalDirection;
export const mpsToKph = WeatherService.mpsToKph;
export const mpsToKnots = WeatherService.mpsToKnots;
