<template>
  <v-snackbar v-model="snackbar" :timeout="timeout">
    <div class="text-center">
      {{ snackbarText }}
      <v-btn v-if="showClose" color="pink" text @click="dismiss">Close</v-btn>
      <v-btn v-if="queuedMessages > 5" color="red" text @click="dismissAll"
        >Close All</v-btn
      >
    </div>
  </v-snackbar>
</template>

<script>
import { bus } from "@/main";

export default {
  data: () => ({
    snackbar: false,
    snackbarText: "",
    showClose: false,
    timeout: 2000,
    defaultTimeout: 2000,
    snackBarWatchers: [],
    queuedMessages: 0,
  }),
  methods: {
    dismiss() {
      this.snackbar = false;
    },
    dismissAll() {
      this.snackBarWatchers.forEach((w) => {
        w();
      });
      this.snackBarWatchers = [];
      this.queuedMessages = 0;
      this.snackbar = false;
    },
    showSnackBar(opts) {
      if (this.queuedMessages) {
        this.queuedMessages--;
      }
      if (opts?.text) {
        this.snackbarText = opts.text;
        this.timeout = opts.timeout || this.defaultTimeout;
        if (opts.showClose) {
          this.showClose = opts.showClose;
        }
        this.snackbar = true;
      } else if (opts && (typeof opts === "string" || opts instanceof String)) {
        this.snackbarText = opts;
        this.timeout = this.defaultTimeout;
        this.snackbar = true;
      }
    },
  },
  created() {
    bus.$on("messageBar", (opts) => {
      if (this.snackbar) {
        this.queuedMessages++;
        const watcher = this.$watch("snackbar", () => {
          watcher();
          this.showSnackBar(opts);
        });
        this.snackBarWatchers.push(watcher);
      } else {
        this.showSnackBar(opts);
      }
    });
  },
};
</script>
