import store from "../store/";

export class PermissionService {
  public hasPermission(scopedPermission: ScopedPermission): boolean {
    if (this.isAdmin()) {
      return true;
    }
    return this.getPermissions().some(
      (sp) =>
        sp.permission === scopedPermission.permission &&
        sp.permissionLevel == scopedPermission.permissionLevel
    );
  }

  public hasPermissionString(scopedPermission: string): boolean {
    const permissionLevel = PermissionService.getLevel(scopedPermission);
    const permission = PermissionService.getPermission(scopedPermission);

    if (!permissionLevel || !permission) {
      return false;
    }

    return this.hasPermission(
      this.scopedPermission(permissionLevel, permission)
    );
  }

  public isAdmin() {
    const adminPermission = this.scopedPermission(
      PermissionLevel.DEL,
      Permission.ADMIN
    );
    return this.getPermissions().some(
      (sp) =>
        sp.permission === adminPermission.permission &&
        sp.permissionLevel == adminPermission.permissionLevel
    );
  }

  private scopedPermission(
    permissionLevel: PermissionLevel,
    permission: Permission
  ): ScopedPermission {
    return { permissionLevel, permission };
  }

  private getPermissions() {
    return store.getters.getUserPermissions;
  }

  public static getLevel(scopedPermission: string): PermissionLevel | null {
    const permissionLevel = Object.keys(PermissionLevel)
      .filter((pl) => isNaN(Number(pl)))
      .find(
        (pl) =>
          pl.toLowerCase() === scopedPermission.split(":")[0].toLowerCase()
      );
    return permissionLevel ? PermissionLevel[permissionLevel] : null;
  }

  public static getPermission(scopedPermission: string): Permission | null {
    const permission = Object.keys(Permission)
      .filter((p) => isNaN(Number(p)))
      .find(
        (p) => p.toLowerCase() === scopedPermission.split(":")[1].toLowerCase()
      );
    return permission ? Permission[permission] : null;
  }
}

export default new PermissionService();

export interface ScopedPermission {
  permissionLevel: PermissionLevel;
  permission: Permission;
}

export enum Permission {
  ADMIN = 1,
  CATCH = 2,
  PRICE = 3,
}

export enum PermissionLevel {
  READ = 1,
  ADD = 2,
  EDIT = 3,
  DEL = 4,
}
