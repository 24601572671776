import axios from "axios";
import {
  Permission,
  PermissionLevel,
  ScopedPermission,
} from "./PermissionService";

class UserService {
  baseUrl = process.env.VUE_APP_API_URL || "/devapi/";

  protected getUrl() {
    return `${this.baseUrl}user`;
  }

  protected getAuthorization(accessToken) {
    return !accessToken
      ? {}
      : {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
  }

  async getUserId(accessToken: any, authUser: any) {
    const user = await this.getUserBySub(accessToken, authUser.sub);
    return user?.id;
  }

  async updateUserWithPermissions(
    accessToken: any,
    authUser: any,
    permissions: ScopedPermission[]
  ) {
    let user = await this.getUserBySub(accessToken, authUser.sub);
    if (!user) {
      user = {};
      user.id = await this.createUser(accessToken, authUser);
    } else {
      await this.updateUser(accessToken, user.id, authUser);
    }
    const data = {
      sub: authUser.sub,
      permissions: permissions.map(
        (p) =>
          PermissionLevel[p.permissionLevel] + ":" + Permission[p.permission]
      ),
    };
    return axios
      .put(
        this.getUrl() + `/${user.id}/permissions`,
        data,
        this.getAuthorization(accessToken)
      )
      .then((resp) => {
        return resp.data.data;
      });
  }

  async createUser(accessToken: any, authUser: any) {
    const data = {
      user: authUser,
      sub: authUser.sub,
    };
    return axios
      .post(this.getUrl(), data, this.getAuthorization(accessToken))
      .then((resp) => {
        return resp.data.data.id;
      });
  }

  async updateUser(accessToken: any, id: number, authUser: any) {
    const data = {
      user: authUser,
      sub: authUser.sub,
    };
    return axios
      .put(this.getUrl() + `/${id}`, data, this.getAuthorization(accessToken))
      .then((resp) => {
        return resp.data.data;
      });
  }

  async updateUserPushover(accessToken: any, id: number, pushoverKey: string) {
    return axios
      .get(this.getUrl() + `/${id}/pushoverKey/${pushoverKey}`, this.getAuthorization(accessToken))
      .then((resp) => {
        return resp.data.data;
      });
  }

  async getUserBySub(accessToken: any, sub: string) {
    const data = {
      sub: sub,
    };
    return axios
      .post(this.getUrl() + `/sub/`, data, this.getAuthorization(accessToken))
      .then((resp) => {
        return resp.data.data;
      });
  }

  async get(accessToken: any, id: number) {
    return axios
      .get(this.getUrl() + `/${id}`, this.getAuthorization(accessToken))
      .then((resp) => {
        return resp.data.data;
      });
  }
}

export default new UserService();
