
import { Component, Vue } from "vue-property-decorator";
import permissionService from "../services/PermissionService";
const ENV = process.env.NODE_ENV || "development";

@Component
export default class Header extends Vue {
  private blinkDuration = 2000;
  private sequenceDuration = 1000 + this.blinkDuration;

  settings = [
    { title: "General", route: "Settings", permission: "del:admin" },
    { title: "Prices", route: "SettingsPrice", permission: "edit:price" },
  ];

  tools = [
    {
      title: "Bulk Price Update",
      route: "BulkPrice",
      permission: "edit:catch",
    },
  ];

  blink() {
    const led = document.getElementById("led");
    if (led) {
      led.classList.add("blink");
      setTimeout(function () {
        led.classList.remove("blink");
      }, this.blinkDuration);
    }
  }

  created() {
    setInterval(this.blink, this.sequenceDuration);
  }

  login() {
    this.$auth.loginWithRedirect();
  }

  logout() {
    this.$auth.logout({
      returnTo: window.location.origin,
    });
  }

  hasPermission(permission: string) {
    return permissionService.hasPermissionString(permission);
  }

  isAdmin() {
    return permissionService.isAdmin();
  }

  toolsVisible() {
    return this.tools.some((t) => this.hasPermission(t.permission));
  }

  isProd() {
    return ENV === "production";
  }
}
